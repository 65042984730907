<template>
  <div class="bg-blue-50 px-6">
      <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
          <div class="px-12 py-6 w text-center">
              <h1 
              class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left whitespace-no-wrap">
                <faIcon
                icon="piggy-bank"
                type="fa"
                class="text-green-500 text-3xl"
                >
                </faIcon>
              Cuentas corrientes
              </h1>
              <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Aquí tienes un resumen de las OT pendientes.</p>
          </div>
          <div class="pr-12 pb-6 pl-10 lg:pb-0 self-center">
            <select 
            v-model="sucursalSeleccionada"
            class="sm:ml-auto mt-3 sm:mt-0 sm:w-auto form-select box border-gray-500">
                <option :value="1">Sucursal 1</option>    
                <option :value="2">Sucursal 2</option>    
                <option :value="3">Sucursal 3</option>    
            </select>              
          </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mt-4">
          <div class="col-span-12 bg-white rounded-lg shadow-md p-5">
              <div class="flex flex-col">
                <div class="px-1 py-6">
                    <h1 class="text-gray-800 text-2xl font-normal sm:text-2xl text-left">Órdenes de trabajo con saldo</h1>
                    <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Estas son las ordenes de trabajo con saldo pendiente.</p>
                </div>               
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-blue-500">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                        Nº OT
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                        Fecha
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                        Acumulado
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                        SALDO POR COBRAR 
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                        estado
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-center text-sm font-medium text-white uppercase tracking-wider">
                                        Valor OT
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200 text-gray-900">
                                <tr v-for="(ot,indice) in listaSeleccionada[sucursalSeleccionada]" :key="indice">
                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="text-center">
                                            {{ ot.numeroOt }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="text-center">
                                             {{ ot.fechaCreacion }} 
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="text-center">
                                            {{ `$ ${formatearCifra(ot.acumulado)}` }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="text-center">
                                            {{ `$ ${formatearCifra(ot.saldo)}` }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="text-center">
                                            {{ ot.credito ? 'Crédito' : 'Contado' }}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="text-center">
                                            {{ `$ ${formatearCifra(ot.valorTotal)}` }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import OrdenTrabajoService from '@/services/OrdenTrabajoService';

import { separarPorMiles } from '@/utils/helpers';

export default {
    name: 'CajaDaria',
    data() {
        return {
            OTPendientes: null,
            sucursalSeleccionada: 1,
            listaSeleccionada: [],
            OTSucursalDos: null,
            OTSucursalTres: null,
            totalesOT: {
                acumulado: 0,
                total: 0,
                saldo: 0
            },
            OTSeleccionados: null
        }
    },
    methods: {
        setOTSucursales() {
            if (this.OTPendientes['1']) {
                this.listaSeleccionada[1] = this.OTPendientes['1'];
            }
            if (this.OTPendientes['2']) {
                this.listaSeleccionada[2] = this.OTPendientes['2'];
            }
            if (this.OTPendientes['3']) {
                this.listaSeleccionada[3] = this.OTPendientes['3'];
            }
        },
        formatearCifra(cifra) {
            return separarPorMiles(cifra);
        }
    },
    mounted() {
        OrdenTrabajoService.getOTPendientes()
        .then((response) => {
            this.OTPendientes = response.data;
            this.setOTSucursales();
        });
    }
}
</script>